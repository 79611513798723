$qa-breakpoint: 768px;

#quick-access {
	// background-color: red;
	padding-right: 0px;
	float: right;
	// height: 450px;

	@media (max-width: $qa-breakpoint) {
	    // width: calc(100% + 15px);
	    left: 0px;
        min-height: inherit !important;

	}

	background-color: #35414f;

	padding-bottom: 30px;
}

.quick-access-block {
	// width: 100%;
	width: calc(100% - 12px);

	@media (max-width: $qa-breakpoint) {
	    width: calc(100% - 15px);
	}

	// height: 180px;
	background-color: #3e4b5c;
	margin-top: 16px;
	color: white;

	display: block;

    padding: 4px;
    padding-left: 53px;
    background-repeat: no-repeat;
    background-position: 2% 50%;
    // background-size: 8%;

    // border-radius: 6px;


	.qa-heading {
		// display: block;
		font-size: 28px;
		margin-top: 12px;
		// text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.87);
		font-weight: bold;
		letter-spacing: 1.3px;
	}

	.qa-title {
		font-size: 13px;
		margin-top: 6px;
		margin-left: 12px;
		// display: block;
	}

	&:hover {
		text-decoration: none;
		color: white;
	}
}



@mixin qa_bg($fname) {
	background-image: url("/static/img/qa/#{$fname}.png");
}



#videos-qa-block {
	background-color: #d84a4a;
	@include qa_bg("videos");
}

#photos-qa-block {
	background-color: #8785ff;
	@include qa_bg("photos");
}

#courses-qa-block {
	background-color: #85c4ff;
	@include qa_bg("courses");
}


#makeups-qa-block {
	background-color: #9fc424;
	@include qa_bg("calendar");
}

#fees-qa-block {
	background-color: #eaaa39;
	@include qa_bg("fees");
}

#lessons-qa-block {
	background-color: #c476e8;
	@include qa_bg("lessons");
}


#bookings-qa-block {
	background-color: #ff8585;
	@include qa_bg("bookings");
}

#credits-qa-block {
	background-color: #ef907a;
	@include qa_bg("credits");
}

#credit_card-qa-block {
	background-color: #ef907a;
	@include qa_bg("credits");
}


#profile-qa-block {
	// background-color: #50a1a5;
	@include qa_bg("profile_c");
	overflow: hidden; // The name can overflow.
}