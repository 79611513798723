/***
jQuery Uniform
***/

.checker {
  margin-top: -2px !important;
  margin-right: 2px !important;
}

.checker input,
.radio input {
  outline: none !important;
}

div.checker.disabled span, 
div.checker.disabled.active span{
    background-position: -152px -260px; 
}

div.checker.disabled:hover,
div.radio.disabled:hover {
    cursor: not-allowed;  
}

div.radio,
div.checker {
 margin-right: 0;
 margin-left: 3px;
}