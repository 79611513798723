/******************************
 3RD PARTY PLUGIN CUSTOMIZATION 
******************************/
  
@import '_variables';     

$theme-type:  'default'; 

@import 'components/_mixins';

@import 'plugins/_bootstrap-colorpicker';
@import 'plugins/_bootstrap-datepaginator';
@import 'plugins/_bootstrap-datepicker';
@import 'plugins/_bootstrap-daterangepicker';         
@import 'plugins/_bootstrap-datetimepicker'; 
@import 'plugins/_bootstrap-editable';         
@import 'plugins/_bootstrap-fileinput';    
@import 'plugins/_bootstrap-markdown';     
@import 'plugins/_bootstrap-modal';              
@import 'plugins/_bootstrap-select';                        
@import 'plugins/_bootstrap-switch';        
@import 'plugins/_bootstrap-timepicker';        
@import 'plugins/_bootstrap-toastr';                              
@import 'plugins/_bootstrap-wizard'; 
@import 'plugins/_bootstrap-tabdrop';   
@import 'plugins/_bootstrap-wysihtml5';       
@import 'plugins/_bootstrap-summercode';
@import 'plugins/_bootstrap-gtreetable';  
@import 'plugins/_bootbox'; 

@import 'plugins/_amchart';
@import 'plugins/_ckeditor';
@import 'plugins/_clockface';     
@import 'plugins/_datatables';       
@import 'plugins/_dropzone';     
@import 'plugins/_fancybox';  
@import 'plugins/_fuelux';
@import 'plugins/_fullcalendar'; 
@import 'plugins/_gmaps';
@import 'plugins/_gritter';    
@import 'plugins/_jquery-easy-pie-chart';
@import 'plugins/_jquery-file-upload';
@import 'plugins/_jquery-multi-select';
@import 'plugins/_jquery-notific8';  
@import 'plugins/_jquery-pwstrength-bootstrap';
@import 'plugins/_jquery-slimscroll';      
@import 'plugins/_jquery-sparkline';
@import 'plugins/_jquery-tags-input';
@import 'plugins/_jquery-ui';
@import 'plugins/_jqvmap';
@import 'plugins/_jstree';
@import 'plugins/_nouislider';
@import 'plugins/_recaptcha';
@import 'plugins/_select2';
@import 'plugins/_uniform';
@import 'plugins/_morris';  
@import 'plugins/_icheck';
@import 'plugins/_ui-select';
@import 'plugins/_minicolors';     








