/***
Select2 Plugin
***/

.form-control .select2-choice {
    border: 1px solid $input-border;
    background-color: #fff;
    background-image: none;
    filter: none;
    height: 34px;
    padding: 3px 0 0px 12px;
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: $input-border;
    background-color: #fff;
    background-image: none;
    filter: none;
}

.select2-drop {
    border: 1px solid $input-border; 
    background-color: #fff;
    background-image: none;    
    -webkit-box-shadow: none;
            box-shadow: none;
    filter: none;
    border-top: 0;
}

.select2-drop-auto-width {
    border-top: 1px solid $input-border;
}

.select2-drop.select2-drop-above {
    border-top: 1px solid $input-border;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.select2-drop-active {
    border: 1px solid $input-border-focus;
    border-top: 0;
}

.select2-container .select2-choice .select2-arrow {
    background-image: none;
    background-color: #fff;
    filter: none;
    border-left: 1px solid $input-border;
}

.select2-container.select2-container-active .select2-arrow,
.select2-container.select2-dropdown-open .select2-arrow {
    border-left: 0 !important;
}

.select2-container .select2-choice .select2-arrow b {
    background-position: 0 1px;
}

.select2-search input {
    border: 1px solid $input-border;
    background-color: #fff !important;
    filter: none;
    margin: 0;
    outline: 0;
    border: 1px solid $input-border;
    webkit-appearance: none !important;   
    color: #333333;         
    outline: 0;    
    box-shadow: none;
    height: auto !important;
    min-height: 26px;
    padding: 6px 6px !important;
    line-height: 20px;
    font-size: 14px;
    font-weight: normal;
    vertical-align: top;  
    background-color: #ffffff;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-top: 5px;
}

.form-control.select2-container {
    border: 0;
    height: auto !important;
    padding: 0px;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid $input-border-focus !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.select2-dropdown-open .select2-choice {
    border-bottom: 0 !important;
    background-image: none;
    background-color: #fff;
    filter: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border: 1px solid $input-border-focus !important;
    border-top: 0 !important;
    background-image: none;
    background-color: #fff;
    filter: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;

}

.select2-drop.select2-drop-above.select2-drop-active {
    border: 1px solid $input-border-focus !important;
    border-bottom: 0 !important;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
    background-position: -18px 1px;
}

.select2-results {
    margin: 5px 0;
}

.select2-results .select2-highlighted {
    background: #eee;
    color: #333;
}

.select2-results li em {
    background: #feffde;
    font-style: normal;
}

.select2-results .select2-highlighted em {  
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: #fff;
    color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    padding: 3px 7px 4px;
    background: #f4f4f4;
    display: list-item;
}

.select2-container-multi {
    @include border-radius(4px);      

    .select2-choices {
        @include border-radius(4px);       
    }

    &.select2-dropdown-open {        
        @include border-radius(4px 4px 0 0);       

        .select2-choices {
            @include border-radius(4px 4px 0 0);       
        }    
    }      

    &.select2-dropdown-open.select2-drop-above {
        @include border-radius(0 0 4px 4px);       

        .select2-choices {
            @include border-radius(0 0 4px 4px);       
        } 
    }
}

.select2-container-multi .select2-choices {
    padding-left: 6px;
    min-height: 34px;
    border: 1px solid $input-border;
    background-image: none;
    background-color: #fff;
    filter: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #999 !important;
    background-image: none;
    background-color: #fff;
    filter: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 18px;
    margin: 5px 0 3px 5px;
    border: 1px solid $input-border;
    background-image: none;
    background-color: #fff;
    filter: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

/***
Color variants
***/

@mixin select2-variant($state, $color) {
    .has-#{$state} .select2-container .select2-choice,
    .has-#{$state} .select2-container .select2-choices {
      border-color: $color;
    }

    .has-#{$state} .select2-container.select2-dropdown-open .select2-choice,
    .has-#{$state} .select2-container.select2-dropdown-open .select2-choices {
      border-color: $color; 
    }

    .has-#{$state} .select2-container.select2-dropdown-open .select2-choice > span {
      color: $color;
    }
}

@include select2-variant("warning", $state-warning-border);
@include select2-variant("error", $state-danger-border);
@include select2-variant("success", $state-success-border);  


/* Fix z-index when select2 opened in modals*/
.modal-open .select2-drop-mask {
    z-index: $zindex-modal + 1;
}

.modal-open .select2-drop {
    z-index: $zindex-modal + 2;
}
 
.modal-open .select2-search {
    z-index: $zindex-modal + 3;
}

/***
Bootstrap input sizes
***/

/* Large input size */

.form-control.input-lg .select2-choice {
    height: 46px;
    padding: 10px 16px;
}

.select2-container.input-lg .select2-choice .select2-arrow b {
    background-position: 0 7px;
}

/* Small input size */

.form-control.input-sm .select2-choice {
    height: 30px;
    padding: 1px 10px 5px 10px;
}

.select2-container.input-sm .select2-choice .select2-arrow b {
    background-position: 0 0px;
}


/* Portlet full screen mode */

.modal-open.page-portlet-fullscreen {
    .select2-drop-mask {
        z-index: $zindex-modal + 31;
    }

    .select2-drop {
        z-index: $zindex-modal + 32;
    }

    .select2-search {
        z-index: $zindex-modal + 33;
    }
}