/***
Morris Charts
***/

.portlet-body-morris-fit {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -15px;

    > svg {
		@include border-radius(0 0 $portlet-border-radius $portlet-border-radius); 
	}
}

.morris-hover.morris-default-style {


	.morris-hover-row-label {
		text-align: left;
		font-weight: 400;
		font-size: 15px;
		color: #7D8C9D;
		font-family: $font-family-primary;     
	}

	.morris-hover-point {
		text-align: left;
		font-size: 14px;
		font-weight: 400;		
		font-family: $font-family-primary;  
	}
}