/***
Dashboard Stats
***/

@mixin dashboard-stat-variant($name, $bg-color, $text-color) {
    &.#{$name} {
        background-color: $bg-color;  

        &.dashboard-stat-light:hover {
            background-color: darken($bg-color, 4%);            
        }

        .visual {
            > i { 
                color: $text-color;
                opacity: 0.1;
                filter: alpha(opacity=10); 
            }
        }

        .details {
            .number {
                color: $text-color; 
            }

            .desc {
                color: $text-color; 
                opacity: 1;
                filter: alpha(opacity=100);    
            }              
        }

        .more {
            color: $text-color;
            background-color: darken($bg-color, 4%);   
        }
    }
}

.dashboard-stat {
    display: block;
    margin-bottom: 25px;
    overflow: hidden;

    @include border-radius($general-border-radius);  

    @include clearfix();

    .portlet &:last-child {
        margin-bottom: 0;
    }

    .visual {
        width: 80px;
        height:80px;
        display: block;
        float: left;
        padding-top: 10px;
        padding-left: 15px;   
        margin-bottom: 15px;  
        font-size: 35px; 
        line-height: 35px; 

        > i {
            margin-left: -35px;  
            font-size: 110px; 
            line-height: 110px;  
        }
    }

    .details { 
        position: absolute;
        right: 15px;
        padding-right: 15px;  
 
        .number {    
            padding-top: 25px;  
            text-align: right; 
            font-size: 34px;
            line-height: 36px;
            letter-spacing: -1px;
            margin-bottom: 0px;     
            font-weight: 300;
        }

        .desc {
            text-align: right;
            font-size: 16px;
            letter-spacing: 0px;
            font-weight: 300;
        }
    }

    .more {
        clear: both;
        display: block;   
        padding: 6px 10px 6px 10px;
        position: relative;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 11px;
        opacity: 0.7;  
        filter: alpha(opacity=70);

        &:hover {
            text-decoration: none;
            opacity: 0.9;  
            filter: alpha(opacity=90);
        }

        > i {
            display: inline-block;
            margin-top: 1px;
            float: right;
        }
    }  
}

.dashboard-stat-light {
    padding-bottom: 20px;
    margin-bottom: 20px;

    .details {
        margin-bottom: 5px;

        .number {
            font-weight: 300;
            margin-bottom: 0px;
        }
    }
}

/***
Dashboard Stats 2
***/

.dashboard-stat2 {
    @include border-radius($general-border-radius);
    background: #fff;
    padding: 15px 15px 30px 15px; 
    margin-bottom: 20px;  

    .display {
        @include clearfix();
        margin-bottom: 20px;

        .number {
            float: left;
            display: inline-block;
            
            h3 {
                margin: 0 0 2px 0;  
                padding: 0;
                font-size: 30px;
                font-weight: 400;

                > small {
                    font-size: 23px;
                }
            }

            small {
                font-size: 14px;
                color: #AAB5BC;
                font-weight: 600; 
                text-transform: uppercase;
            }

        }

        .icon {
            display: inline-block;
            float: right;
            padding: 7px 0 0 0;

            > i {
                color: #cbd4e0;
                font-size: 26px;
            }
        }  
    }

    .progress-info {
        clear:both;

        .progress { 
            margin: 0;        
            height: 4px;
            clear:both;
            display: block; 
        }

        .status {
            margin-top: 5px;
            font-size: 11px;
            color: #AAB5BC;
            font-weight: 600; 
            text-transform: uppercase;

            .status-title {
                float: left;
                display: inline-block;
            }

            .status-number {
                float: right;
                display: inline-block;
            }
        }
    }

    
}

/***
Text Stats
***/

.text-stat {
    h3 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 18px;
    }

    span {
        font-size: 13px !important;
    }
}

@media (max-width: $screen-xs-max) { /* 767px */  

  .text-stat {
    margin-top: 20px;
  }  

}
