/******************
Page Quick Sidebar
******************/

/***
Quick Sidebar Layout
***/

/* Quick sidebar toggler */

.page-header {
	.top-menu {
		.dropdown-quick-sidebar-toggler {
			> .dropdown-toggle {
				padding: $header-top-menu-general-item-padding !important; 
				i {
					top: 0px;  

					.page-quick-sidebar-open &:before {
						content: "\e066";
					}
				}
			}
		}
	}
}

/* Quick sidebar wrapper */

.page-quick-sidebar-wrapper {
	transition: right 0.3s;	
	z-index: $zindex-quick-sidebar;
	position: fixed;
	top: $header-height;
	bottom: 0;
	right: -$quick-sidebar-width;
	width: $quick-sidebar-width;

	.page-footer-fixed & {
		bottom: $page-footer-height; 
	}

	.page-quick-sidebar-full-height & {
		top: 0;
		bottom: 0;
	}

	.page-quick-sidebar-open & {
		transition: right 0.3s;	
		right: 0;
	}
}

/*** 
Quick Sidebar Toggler 
***/

.page-quick-sidebar-toggler {
	overflow: hidden;
	z-index: $zindex-quick-sidebar;
	display: none;
	width: 28px;
	height: 27px;
	position: fixed;
	top: $header-height - 27px; 
	right: 15px;
	text-align: center;
	padding-top: 6px;

	.page-quick-sidebar-open & {
		display: inline-block;
	}

	> i {
		font-size: 17px;
	}
}

/* quick sidebar top position fix for mobile view */
@media (max-width: $screen-xs-min) { /* 480px */
	.page-quick-sidebar-wrapper {
		top: $header-height-xs;
	}

	.page-quick-sidebar-toggler {
		top: $header-height-xs - 27px;
	}
}

.page-header,
.page-sidebar-wrapper,
.page-content-wrapper,
.page-footer {
	transition: margin 0.3s;
}

/* Quick sidebar with push content */

.page-quick-sidebar-open.page-quick-sidebar-push-content {
	.page-sidebar-wrapper {
		transition: margin-left 0.3s;
		margin-left: -$quick-sidebar-width;
	}	

	.page-content-wrapper {
		transition: margin-left 0.3s; 
		margin-left: -$quick-sidebar-width;
	}

	.page-footer {
		transition: margin-right 0.3s;
		margin-right: $quick-sidebar-width;  
		margin-left: - $quick-sidebar-width;    
	}
}


/* Page sidebar reversed */

.page-sidebar-reversed.page-quick-sidebar-open.page-quick-sidebar-push-content {
	.page-sidebar-wrapper {
		transition: margin-right 0.3s;
		margin-right: $quick-sidebar-width;
	}
}

/* Quick sidebar with full height content */

.page-quick-sidebar-open.page-quick-sidebar-push-content.page-quick-sidebar-full-height {
	.page-header {
		transition: margin-left 0.3s;
		margin-left: - $quick-sidebar-width; 
	}
}	

.page-quick-sidebar-open.page-quick-sidebar-push-content.page-footer-fixed:not(.page-quick-sidebar-full-height) {       
	.page-footer {
		margin-left: 0;
		margin-right: 0; 
	}
}

/* Quick sidebar with transparent content */

.page-quick-sidebar-open.page-quick-sidebar-over-content-transparent {
	.page-quick-sidebar-wrapper {
		opacity: 0.9;
        filter: alpha(opacity=90);
	}
}

/* Hide the responsive menu if its shown while the quick sidebar opened on mobile  */

@media (max-width: $screen-sm-max) { /* 991px */
	.page-quick-sidebar-open.page-quick-sidebar-push-content {
		.page-sidebar-wrapper {
			display: none;
		}
	}	
}

/***
Quick Sidebar Content
***/

.page-quick-sidebar-wrapper {
	overflow: hidden;

	.page-quick-sidebar {

		/* Quick sidebar tabs */
		.nav-justified {
			margin: 0;
			padding: 0;

			> li {
				display: table-cell !important;
				width: 1%  !important;

				> a {
					padding: $quick-sidebar-tab-item-padding;
					border: 0;
					height: $header-height;
					font-size: 13px;
					text-transform: uppercase;

                    @include border-radius(0); 

					> .badge {
						position: absolute;
						top: 12px;
						right: 3px;
					}

					&:hover {
						border: 0;
					}
				}

				.dropdown-menu {	  
	               	margin-top: 8px;                   
	                margin-right: 20px;  

	                &:before {
						position: absolute;
						top: -7px;
						right: 19px;
						display: inline-block !important;
						border-right: 7px solid transparent;
						border-left: 7px solid transparent;
						border-bottom-color: rgba(0, 0, 0, 0.2);
						content: '';
					}   

					&:after {
						position: absolute;
						top: -6px;
						right: 20px;
						display: inline-block !important;
						border-right: 6px solid transparent;
						border-bottom: 6px solid #fff;
						border-left: 6px solid transparent;
						content: '';
					}
	            }
			}
		}

		/* Quick sidebar tabs content */
		.nav-justified {
			> .tab-content {
				margin: 0;
				padding: 0;
			}
		}

		/* Quick sidebar general list heading */
		.list-heading {
			font-size: 16px;
			margin: 10px 10px;
		}

		/* Quick sidebar general list-items */
		.list-items {
			margin: 0;
			padding: 0;
			list-style: none;

			> li {
				margin: 0;
		        padding: 10px;
		        background: none;
		        border-bottom-width: 1px;
		        border-bottom-style: solid;

	            &:last-child {
		            border-bottom: 0;
		        }

		        &:hover { 
		        }
	    	}

	    	&.borderless {
	    		li {
	    			border: 0;  
		        }
	    	}
		}

		/* Inner content */
		.inner-content {
			margin: 10px 10px;
		}

		/* Quick sidebar list */
		.page-quick-sidebar-list {
			position: absolute !important;
			width: $quick-sidebar-width !important; 
			transition: margin 0.3s; 
		}

		/* Quick sidebar list item */
		.page-quick-sidebar-item {
			position: absolute !important;
			width: $quick-sidebar-width  !important; 
			transition: margin 0.3s; 
			margin-left: $quick-sidebar-width; 

			.page-quick-sidebar-nav {
				padding: 15px 10px 0px 10px;

				.page-quick-sidebar-back-to-list {
	                vertical-align: middle;  
	                display: inline-block;
	                font-size: 14px;

	                > i {
	                    font-size: 17px;
	                    line-height: 17px;
	                    vertical-align: top;
	                    margin-right: 3px;
	                }
	            }
			}            
		}	

		/* Quick sidebar list item shown */
		.page-quick-sidebar-content-item-shown {
			.page-quick-sidebar-list {
				transition: margin 0.3s; 
				margin-left: - $quick-sidebar-width; 

				.slimScrollBar,
				.slimScrollRail {
					display: none !important;  
				}
			}

			.page-quick-sidebar-item {
				transition: margin 0.3s;
				margin-left: 0;  
			} 
		}

		/* Quick sidebar chat */
		.page-quick-sidebar-chat {
			.page-quick-sidebar-chat-users {
				padding: 10px 0;
				position: relative;
						
				.media-list {
					.media {
						padding: 12px 10px 11px 10px;

						.media-object {							
				            border-radius: 50% !important;
							width:  $quick-sidebar-width/7;
							opacity: 0.8;
	           				filter: alpha(opacity=80);
							float: left;
							margin-right: 10px;

							@include clearfix();
						}

						&:hover {
							cursor: pointer;

							.media-object {
								opacity: 1;
		                		filter: alpha(opacity=100);
							}
						}

						.media-body {

							.media-heading {
								margin: 5px 0 0 0;
								font-size: 14px;
							}

							.media-heading-sub {
								font-size: 11px;
								text-transform: uppercase;
							}

							.media-heading-small {
								font-size: 10px;								
							}
						}

						.media-status {
							margin-top: 10px; 
							right: 10px;
							position: absolute;
							display: inline-block;
						}
					}
				}
			}

			.page-quick-sidebar-chat-user {

				.page-quick-sidebar-chat-user-messages { 
					padding: 0px 10px;
					position: relative;

					.post {
						transition: display 0.3s;
				        padding: 5px 0;
				        margin: 10px auto;
				        font-size: 12px;

				        .body {
				            display: block;
				        }
        
				        .avatar {
				            width:$quick-sidebar-width/7;
				            border-radius: 50% !important;
				        }

				        &.in .avatar {
				            float: left;
				            margin-right: 10px;
				        }

				        &.out .avatar {
				            float: right;
				            margin-left: 10px;
				        }

				        .name {
				            font-size: 12px;
				            font-weight: 300;
				        }
				        
				        .datetime {
				            font-size: 12px;
				            font-weight: 300;
				        }
				       
				        .message {			
				            display: block;
				            padding: 5px;
				            position: relative;
				        }

				        &.in {
				        	.message {
				            	text-align: left;
				            	margin-left: 55px;
				      
				            	.arrow {
				                	display: block;
				                	position: absolute;
				                	top: 9px;
				                	left: -6px;
				                	width: 0;
				                	height: 0;
				                	border-top: 6px solid transparent;
				                	border-bottom: 6px solid transparent;
				                	border-right-width: 6px;
				                	border-right-style: solid;
				                }
				            }
				        }

				        &.out {
				            .message {
				                margin-right: 55px;
				                text-align: right;

				                .arrow {
				                    display: block;
				                    position: absolute;
				                    top: 9px;
				                    right: -6px;
				                    border-top: 6px solid transparent;
				                    border-bottom: 6px solid transparent;
				                    border-left-width: 6px;
				                	border-left-style: solid;
				                }  
				            }
				  
				            .name, 
				            .datetime {
				                text-align: right;
				            }
				        }
				    }
				}

				.page-quick-sidebar-chat-user-form {
					padding: 20px 10px 15px 10px;

					.input-group {
						.form-control {
							font-size: 13px;   
						}
					}
				}
			}
		}

		/* Quick sidebar alerts */
		.page-quick-sidebar-alerts {
			
			.page-quick-sidebar-alerts-list {
				padding: 10px 0;
				position: relative;

	            .feeds {
	                li {	                	
	                    .label {
	                        margin-top: 5px;
	                    }

	                    .desc {
	                        padding: 0;
	                    }
	                }
	            }
	        }
		}

		/* Quick sidebar settings */
		.page-quick-sidebar-settings {

			.page-quick-sidebar-settings-list {
				padding: 10px 0;
				position: relative;

				.list-items {
					li {
						.bootstrap-switch {
							margin-top: -3px;
							float: right;
							border: 0;
							min-width: 59px;
						}	

						.form-control {
							width: 75px !important;							
							padding: 4px 4px !important;
							float: right;
							border: 0;
							margin-top: -4px;
						}

						select.form-control {   							
							padding: 4px 0px !important;
						}
					}
				}
			}				
		}
	}
}
