/***
JQVMAP Plugin
***/

.jqvmap-zoomin {
  height: 16px;
  width: 16px;
  background-color: #666 ;
}

.jqvmap-zoomout {
  height: 16px;
  width: 16px;
 background-color: #666 ; 
}

.vmaps {
  position: relative; 
  overflow: hidden;
  height: 300px;
}