

thead tr {
	background-color: #ff8965;
	color: white;
}

// The calendar also uses tables; don't subject its headers to the orange styling

.calendar, .datepicker {
	thead tr {
		background-color: inherit !important;
		color: inherit !important;
	}
}


thead th {
	border: none !important;
}

tbody tr {
	td {
		border: none !important;
	}
	margin-top: 8px;
}