#sidebar-toggler-in-sidebar {
	@media (max-width: 991px) {
		display: none;
	}
}

.top-menu .dropdown-toggle {
	line-height: 70px;
}

.white-arrow {
    top: 6px;
    position: absolute;
    right: 0;
}

.page-header-inner {
	padding-right: 0px;
	height: 100%;
}

.sidebar-toggler {


    // margin-left: -26px !important;

	@media (min-width: 990px) {
		float: left !important;
		left: -26px;
		position: relative;
	}

}

.page-header.navbar .page-logo {
    // margin-right: -26px;
}


.page-content-wrapper {
	padding-right: 0px !important;

	@media (max-width: 990px) {
		padding-left: 6px !important;
	}

}

#page-content {
	// width: 100%;


	@media (max-width: 990px) {
		// padding-left: 0px !important;
	}

}

.col-header-hack {
	// width: 250px;
	padding-right: 0px !important;

	@media (min-width: 600px) {
		min-width: 241px;
	}

    
}

@media (min-width: 1420px) {
	.col-header-hack {
		width: 16.66666667%;
	}
}


#choose-lang {
    margin-top: 40px;
}

@import "bootstrap3-dialog/src/css/bootstrap-dialog";

.very-high-zindex{
	z-index: 999999 !important;
}


#version-number {
	float: right;
	color: white;

    margin-right: 12px;
    margin-top: 4px;

}


.input-date-component {
	display: inline;
	width: auto !important;
	margin-left: 4px;
}

.input-date-day {
	margin-left: 0px;
}

.force-inline {
	display: inline !important;
}

.expired-link {
	color: #6c7b89;
}

.expired-note {
    margin-left: 0.5em;
    font-weight: bold;
}


.status {
	// display: block;
	font-weight: bold;
	line-height: 20px;
	vertical-align: bottom;
}

.ok {
	color: green;
}

.bad {
	color: red;
}

.logo-2 {
	height: 87%;
	margin-top: 5px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.menu-toggler-v2 {
	display: block;
	cursor: pointer; 
	opacity: 0.7;
	filter: alpha(opacity=70);
	width: $header-menu-toggler-width;
	height: $header-menu-toggler-height;
	height: 22px;
	background-repeat: no-repeat;
	background-position: center center;

	// background: url(../../img/sidebar_toggler_icon_#{$theme-name}.png); 

}

.page-container {
	display: flex;

	@media (max-width: 991px) {
		flex-direction: column;
	}

}

.page-sidebar-wrapper {
	// width: 300px;


	width: 250px;
	flex-shrink: 0;

	@media (max-width: 991px) {
		width: 100% !important;
	}

}

#quick-access {
	// min-width: 300px;
	min-width: 285px;
	width: 285px;
    padding-left: 12px;

	@media (max-width: 991px) {
		width: 100% !important;
	}

}

.page-content {
	flex-grow: 1;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 25px;
    padding-bottom: 20px;
}

#todays_lesson_btn_link, #todays_lesson_btn_link:hover {
	background-color: #eaaa39;
    color: white !important;
    font-weight: bold;
	font-size: 16px;
}